import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import API from "../../../helpers/api";

const DataLicense = () => {
    const [loading, setLoading] = useState(false);
    const [reg, setReg] = useState([]);

    const loadData = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/umdpc/data/license`);
            console.log("response ==>", res);
            setReg(res?.data || []);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="mb-4 d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Licensed Health Workers</h4>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div class="table-responsive">
                            <table class="table table-sm">
                                <thead class="table-light striped">
                                    <tr>
                                        <th>Licence_number</th>
                                        <th>NIN</th>
                                        <th>Qualification</th>
                                        <th>Registration Date</th>
                                        <th>Date of Issue</th>
                                        <th>Date of Expiry</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? <Spinner /> : reg.map((row, index) => (
                                        <tr key={index}>
                                            <td>{row.licence_number}</td>
                                            <td>{row.nin}</td>
                                            <td>{row.qualification}</td>
                                            <td>{moment(row.date_of_registration).format('YYYY-MM-DD')}</td>
                                            <td>{moment(row.date_of_issue).format('YYYY-MM-DD')}</td>
                                            <td>{moment(row.date_of_expiry).format('YYYY-MM-DD')}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default DataLicense;
