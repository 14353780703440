import React, { useState, useEffect, Fragment } from 'react'
import API from "../../../helpers/api";
// import TrendChart from './TrendChart';
// import IssueDistribution from './IssueDistribution';
import Filters from '../../../components/FNFilters';
import Licensed from './Licensed';
import Registered from './Registered';
import DataRegistration from './DataRegistration';
import DataLicense from './DataLicense';

const Medical = () => {

    const [loading, setLoading] = useState(false);
    const [registration, setRegistration] = useState(0);
    const [licensed, setLicensed] = useState(0);
    const [privatereg, setPrivateReg] = useState(0);
    const [privatelic, setPrivateLic] = useState(0);

    const loadRegistration = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/umdpc/registration`);
            setRegistration(res?.data.count);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const loadLicensed = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/umdpc/licensed`);
            setLicensed(res?.data.count);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    console.log(registration)

    useEffect(() => {
        loadRegistration();
        loadLicensed();
    }, []);

    return (
        <Fragment>
            <div class="row">
                <div class="col-12">
                    <div class="mb-4 d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0 font-size-18">Registration and Licensing Dashboard - Uganda Medical and Dental Professional Council</h4>
                    </div>
                </div>
            </div>
            {/* <Filters /> */}
            <div class="row">
                <div class="col-md">
                    <div class="card mini-stats-wid widget-three">
                        <div class="card-body">
                            <h4 class="mb-0">{licensed && licensed}</h4>
                            <p class="text-muted fw-medium">Total Number of Licensed Health Workers</p>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid widget-two-info">
                        <div class="card-body">
                            <h4 class="mb-0">{registration}</h4>
                            <p class="text-muted fw-medium">Total Number of Registered Health Workers</p>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid widget-two-warning">
                        <div class="card-body">
                            <h4 class="mb-0">{privatereg && privatereg}</h4>
                            <p class="text-muted fw-medium">Total Number of Registered Private Health Facilities</p>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid widget-two-success">
                        <div class="card-body">
                            <h4 class="mb-0">{privatelic && privatelic}</h4>
                            <p class="text-muted fw-medium">Total Number of Licensed Private Health Facilities</p>
                        </div>
                    </div>
                </div>
            </div>
            <ul class="nav nav-tabs nav-tabs-custom" role="tablist">
                <li class="nav-item" role="presentation">
                    <a class="nav-link active" data-bs-toggle="tab" href="#all-order" role="tab" aria-selected="true">
                        Licensed Health Workers
                    </a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" data-bs-toggle="tab" href="#processing" role="tab" aria-selected="false" tabindex="-1">
                        Registered Health Workers
                    </a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" data-bs-toggle="tab" href="#reg" role="tab" aria-selected="false" tabindex="-1">
                        Registration Data
                    </a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" data-bs-toggle="tab" href="#license" role="tab" aria-selected="false" tabindex="-1">
                        Licensing Data
                    </a>
                </li>
            </ul>
            <div class="tab-content p-3">
                <div class="tab-pane active show" id="all-order" role="tabpanel">
                    <div class="row">
                        <div class="col-lg-12">
                            <Licensed />
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="processing" role="tabpanel">
                    <div class="row">
                        <div class="col-lg-12">
                            <Registered />
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="reg" role="tabpanel">
                    <div class="row">
                        <div class="col-lg-12">
                            <DataRegistration />
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="license" role="tabpanel">
                    <div class="row">
                        <div class="col-lg-12">
                            <DataLicense />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Medical