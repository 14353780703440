import React, { useState, useEffect, Fragment } from 'react';
import { Spinner } from 'react-bootstrap';
import API from '../../helpers/api';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, LabelList, ResponsiveContainer } from 'recharts';

const Facilities = () => {

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AA46BE', '#EF7674'];

  const [data, setData] = useState([]);
  const [tool, setTool] = useState([]);
  const [owner, setOwner] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await API.get('/uhf');
      const chartData = response.data.map(item => ({
        name: item.registration_authority,
        value: parseInt(item.count, 10)
      }));
      setData(chartData);
    } catch (error) {
      console.error('Error fetching facility counts:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadOwnership = async () => {
    setLoading(true);
    try {
      const response = await API.get('/uhf/ownership');
      const chartData = response.data.map(item => ({
        name: item.facility_ownership,
        value: parseInt(item.count, 10)
      }));
      setOwner(chartData);
    } catch (error) {
      console.error('Error fetching facility counts:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadTool = async () => {
    setLoading(true);
    try {
      const response = await API.get('/uhf/tool');
      const chartData = response.data.map(item => ({
        name: item.tool,
        value: parseInt(item.count, 10)
      }));
      setTool(chartData);
    } catch (error) {
      console.error('Error fetching facility counts:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
    loadTool();
    loadOwnership();
  }, []);

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-4">Number of Facilities Assessed Per Registration Authority</h4>
              {loading ? (
                <Spinner />
              ) : (
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart
                    data={data}
                    margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" angle={-35} textAnchor="end" interval={0} />
                    <YAxis allowDecimals={false} />
                    <Tooltip />
                    <Bar dataKey="value" fill="#4682B4" name="Facility Count">
                      <LabelList dataKey="value" position="top" />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-4">Number of Facilities Assessed Per Type of Ownership</h4>
              {loading ? (
                <Spinner />
              ) : (
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart
                    data={owner}
                    margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" angle={-35} textAnchor="end" interval={0} />
                    <YAxis allowDecimals={false} domain={[0, 2500]} />
                    <Tooltip />
                    <Bar dataKey="value" fill="#8884d8" name="Facility Count">
                      <LabelList dataKey="value" position="top" />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-4">Number of Facilities Assessed Per Tool</h4>
              {loading ? (
                <Spinner />
              ) : (
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart
                    data={tool}
                    margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" angle={-10} textAnchor="end" interval={0} />
                    <YAxis allowDecimals={false} />
                    <Tooltip />
                    <Bar dataKey="value" fill="#EF7674" name="Facility Count">
                      <LabelList dataKey="value" position="top" />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Facilities;