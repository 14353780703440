import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import API from "../../../helpers/api";
import Filters from '../../../components/FNFilters';

const FacilityScores = () => {
    const [loading, setLoading] = useState(false);
    const [scores, setScores] = useState([]);
    const [filters, setFilters] = useState({});

    const loadData = async (filterParams = {}) => {
        setLoading(true);
        try {
            // Convert the filterParams object into a query string
            const query = new URLSearchParams(filterParams).toString();
            const res = await API.get(`/filters/search?${query}`);
            console.log("response ==>", res);
            setScores(res?.data || []);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const handleFilterChange = (selectedFilters) => {
        setFilters(selectedFilters); // Store the selected filters
        loadData(selectedFilters);   // Fetch the filtered data
    };

    const getColorByScore = (score) => {
        if (score >= 0 && score < 50) return 'red';
        if (score >= 50 && score < 80) return 'yellow';
        if (score >= 80) return 'green';
        return 'grey';
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        console.log("Updated Scores:", scores);
    }, [scores]);

    return (
        <Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="mb-4 d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Facility Module Performance Scorecard Dashboard</h4>
                    </div>
                </div>
            </div>
            <Filters onFilterChange={handleFilterChange} />
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div class="table-responsive">
                            <table class="table table-sm">
                                <thead class="table-light">
                                    <tr>
                                        <th>Health Facility</th>
                                        {/* <th>Assessment Date</th> */}
                                        <th>Leadership</th>
                                        <th>Human Resources</th>
                                        <th>Health Financing</th>
                                        <th>Health Information</th>
                                        <th>Medcines</th>
                                        <th>Health Infrastucture</th>
                                        <th>RMNCAH</th>
                                        <th>Clinical Services</th>
                                        <th>Clinical Services</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? <Spinner /> : scores.map((row, index) => (
                                        <tr key={index}>
                                            <td><Link to={`/hfqap/performance/${row.facility}`}>{row.facility}</Link></td>
                                            {/* <td>{moment(row.event_date).format('YYYY-MM-DD')}</td> */}
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.module_1)) }}>
                                                {row.module_1}%
                                            </td>
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.module_2)) }}>
                                                {row.human_module_2}%
                                            </td>
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.module_3)) }}>
                                                {row.module_3}%
                                            </td>
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.module_4)) }}>
                                                {row.module_4}%
                                            </td>
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.module_5)) }}>
                                                {row.module_5}%
                                            </td>
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.module_6)) }}>
                                                {row.module_6}%
                                            </td>
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.module_7)) }}>
                                                {row.module_7}%
                                            </td>
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.module_8)) }}>
                                                {row.module_8}%
                                            </td>
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.module_9)) }}>
                                                {row.module_9}%
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default FacilityScores