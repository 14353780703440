import React, { useState, useEffect, Fragment } from 'react';
import Select from 'react-select';
import API from "../../../helpers/api";

const Filters = ({ close, applyFilters }) => {
    const [loading, setLoading] = useState(false);
    const [region, setRegion] = useState(null);
    const [year, setYear] = useState(null);
    const [tool, setTool] = useState(null);
    const [district, setDistrict] = useState(null);
    const [division, setDivision] = useState(null);
    const [facility, setFacility] = useState(null);

    const [tools, setTools] = useState([]);
    const [years, setYears] = useState([]);
    const [regions, setRegions] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [facilities, setFacilities] = useState([]);

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                const [regionRes, yearRes, toolRes, districtRes, divisionRes, facilityRes] = await Promise.all([
                    API.get(`/assessment/region`),
                    API.get(`/assessment/year`),
                    API.get(`/assessment/tool`),
                    API.get(`/assessment/district`),
                    API.get(`/assessment/divisions`),
                    API.get(`/assessment/facility`),
                ]);

                setRegions(regionRes.data.map(item => ({ value: item.region, label: item.region })));
                setYears(yearRes.data.map(item => ({ value: item.year, label: item.year })));
                setTools(toolRes.data.map(item => ({ value: item.tool, label: item.tool })));

                setDistricts(districtRes.data.map(item => ({
                    value: item.district,
                    label: item.district,
                    region: item.region,
                })));

                setDivisions(divisionRes.data.map(item => ({
                    value: item.division,
                    label: item.division,
                    district: item.district,
                })));

                setFacilities(facilityRes.data.map(item => ({
                    value: item.facility_name,
                    label: item.facility_name,
                    division: item.division,
                })));

            } catch (error) {
                console.log("Error loading data:", error);
            } finally {
                setLoading(false);
            }
        };
        loadData();
    }, []);

    const filteredDistricts = region ? districts.filter(d => d.region === region.value) : districts;
    const filteredDivisions = district ? divisions.filter(d => d.district === district.value) : divisions;
    const filteredFacilities = division ? facilities.filter(d => d.division === division.value) : facilities;

    const clearFilters = () => {
        setYear(null);
        setTool(null);
        setRegion(null);
        setDistrict(null);
        setDivision(null);
        setFacility(null);
    };

    const handleFilter = () => {
        applyFilters({ region, year, tool, district, division, facility });
        close();
    };

    return (
        <Fragment>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card job-filter">
                        <div className="card-body p-3">
                            <div className="row">
                                <div className="col-6 mb-3">
                                    <div className="position-relative">
                                        <Select
                                            value={year}
                                            onChange={setYear}
                                            options={years}
                                            placeholder="Select Year"
                                            isClearable
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="position-relative">
                                        <Select
                                            value={tool}
                                            onChange={setTool}
                                            options={tools}
                                            placeholder="Select Tool"
                                            isClearable
                                        />
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <div className="position-relative">
                                        <Select
                                            value={region}
                                            onChange={(selectedRegion) => {
                                                setRegion(selectedRegion);
                                                setDistrict(null); // Reset district when region changes
                                            }}
                                            options={regions}
                                            placeholder="Select Region"
                                            isClearable
                                        />
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="position-relative">
                                        <Select
                                            value={district}
                                            onChange={setDistrict}
                                            options={filteredDistricts}
                                            placeholder="Select District"
                                            isClearable
                                        />
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <div className="position-relative">
                                        <Select
                                            value={division}
                                            onChange={setDivision}
                                            options={filteredDivisions}
                                            isClearable
                                            placeholder="Select Division"
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="position-relative">
                                        <Select
                                            value={facility}
                                            onChange={setFacility}
                                            options={filteredFacilities}
                                            placeholder="Select Facility"
                                            isClearable
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="position-relative h-100 hstack">
                                        <div className="flex-shrink-0">
                                            <a href="#!" onClick={handleFilter} className="btn btn-warning mx-1">
                                                <i className="mdi mdi-refresh"></i> Apply Filters
                                            </a>
                                            <a href="#!" onClick={clearFilters} className="btn btn-primary">
                                                <i className="mdi mdi-filter-outline align-middle"></i> Clear Filters
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Filters;
