import React, { useState, useEffect } from 'react';
import Select from "react-select";
import { Spinner } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import API from "../../../helpers/api";

const FNFilters = ({ onFilterChange }) => {

    const [loading, setLoading] = useState(false);
    const [region, setRegion] = useState("");
    const [district, setDistrict] = useState("");
    const [regions, setRegions] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [filteredDistricts, setFilteredDistricts] = useState([]);

    const [module, setModule] = useState("");
    const [modules, setModules] = useState([]);

    const [facility, setFacility] = useState("");
    const [facilities, setFacilities] = useState([]);
    const [filteredFacilities, setFilteredFacilities] = useState([]);

    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");

    const loadRegions = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/filters/regions`);
            setRegions(res?.data || []);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const loadDistricts = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/filters/districts`);
            setDistricts(res?.data || []);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const loadFacilities = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/filters/facilities`);
            setFacilities(res?.data || []);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const loadModules = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/filters/stands`);
            setModules(res?.data || []);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadRegions();
        loadDistricts();
        loadFacilities();
        loadModules();
    }, []);

    useEffect(() => {
        if (region) {
            const filtered = districts.filter(d => d.region === region);
            setFilteredDistricts(filtered);
        } else {
            setFilteredDistricts([]);
        }
    }, [region, districts]);

    useEffect(() => {
        if (district) {
            const filtered = facilities.filter(d => d.district === district);
            setFilteredFacilities(filtered);
        } else {
            setFilteredFacilities([]);
        }
    }, [district, facilities]);

    const handleFilterChange = async () => {
        const params = {};

        if (region) params.region = region;
        if (district) params.district = district;
        if (facility) params.facility = facility;
        if (module) params.module = module;
        if (FromDate) params.fromDate = FromDate.toISOString().split('T')[0];
        if (ToDate) params.toDate = ToDate.toISOString().split('T')[0];

        // Pass the selected filters to the parent component (FacilityScores)
        onFilterChange(params);

        console.log("Filtered Params ===>", params)
    };

    const clear = () => {
        setRegion("");
        setDistrict("");
        setFacility("");
        setFromDate("");
        setToDate("");
        setModule("");
        setFilteredDistricts([]);
        setFilteredFacilities([]);
    };


    return (
        <div class="row">
            <div class="col-lg-12">
                <div class="card job-filter">
                    <div class="card-body p-3">
                        <div class="row">
                            <div class="col-2">
                                <div class="position-relative">
                                    <div id="datepicker1">
                                        <label>Select Region</label>
                                        <select
                                            className="form-select"
                                            value={region}
                                            onChange={(e) => setRegion(e.target.value)}
                                        >
                                            <option value="">Select Region</option>
                                            {regions.map((r, index) => (
                                                <option key={index} value={r.region}>{r.region}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="position-relative">
                                    <label>District</label>
                                    <select
                                        className="form-select"
                                        value={district}
                                        onChange={(e) => setDistrict(e.target.value)}
                                    >
                                        <option value="">Select District</option>
                                        {filteredDistricts.map((r, index) => (
                                            <option key={index} value={r.district}>{r.district}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="position-relative">
                                    <label>Facility</label>
                                    <select
                                        className="form-select"
                                        value={facility}
                                        onChange={(e) => setFacility(e.target.value)}
                                    >
                                        <option value="">Select Facility</option>
                                        {filteredFacilities.map((r, index) => (
                                            <option key={index} value={r.facility}>{r.facility}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="position-relative">
                                    <label>Modules</label>
                                    <select
                                        className="form-select"
                                        value={module}
                                        onChange={(e) => setModule(e.target.value)}
                                    >
                                        <option value="">Select Module</option>
                                        {modules.map((r, index) => (
                                            <option key={index} value={r.module}>{r.module}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="position-relative">
                                    <label>From Date</label>
                                    <DatePicker
                                        selected={FromDate}
                                        onChange={(date) => setFromDate(date)}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="select From Date"
                                        className="form-control"
                                        wrapperClassName="form-select"
                                    />
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="position-relative">
                                    <label>To Date</label>
                                    <DatePicker
                                        selected={ToDate}
                                        onChange={(date) => setToDate(date)}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="Select To Date"
                                        className="form-control"
                                        wrapperClassName="form-select"
                                    />
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="position-relative h-100 hstack gap-3">
                                    <div class="flex-shrink-0">
                                        <a href="#!" onClick={handleFilterChange} class="btn btn-primary">
                                            <i class="mdi mdi-filter-outline align-middle"></i>Filter</a>
                                        <a href="#!" onClick={clear} class="btn btn-light mx-2"><i class="mdi mdi-refresh"></i> Clear</a>
                                        {/* <a href="#!" onClick={downloadExcel} class="btn btn-success"><i class="bx bx-down-arrow-alt"></i> </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FNFilters