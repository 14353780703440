import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, LabelList } from 'recharts';
import API from '../../helpers/api';

const AverageScores = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadScores = async () => {
    setLoading(true);
    try {
      const res = await API.get('/assessment/average');
      const chartData = Object.keys(res.data)
        .map((key) => ({
          name: key.replace(/_/g, ' '),
          value: res.data[key],
        }))
        .sort((a, b) => b.value - a.value);

      setData(chartData);
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadScores();
  }, []);

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <BarChart layout="vertical" width={800} height={500} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis dataKey="name" type="category" width={200} /> {/* Increased width for spacing */}
          <Tooltip />
          <Legend />
          <Bar dataKey="value" fill="#8884d8">
            <LabelList dataKey="value" position="right" offset={10} formatter={(value) => `${value}%`} /> {/* Add % sign */}
          </Bar>
        </BarChart>
      )}
    </div>
  );
};

export default AverageScores;