import React, { useState, useEffect, Fragment } from 'react';
import Select from 'react-select';
import API from "../../helpers/api";

const AverageFilter = ({ close, applyFilters }) => {
    const [loading, setLoading] = useState(false);
    const [region, setRegion] = useState(null);
    const [year, setYear] = useState(null);
    const [tool, setTool] = useState(null);

    const [tools, setTools] = useState([]);
    const [years, setYears] = useState([]);
    const [regions, setRegions] = useState([]);

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                const [regionRes, yearRes, toolRes] = await Promise.all([
                    API.get(`/assessment/region`),
                    API.get(`/assessment/year`),
                    API.get(`/assessment/tool`),
                ]);

                setRegions(regionRes.data.map(item => ({ value: item.region, label: item.region })));
                setYears(yearRes.data.map(item => ({ value: item.year, label: item.year })));
                setTools(toolRes.data.map(item => ({ value: item.tool, label: item.tool })));

            } catch (error) {
                console.log("Error loading data:", error);
            } finally {
                setLoading(false);
            }
        };
        loadData();
    }, []);

    const clearFilters = () => {
        setYear(null);
        setTool(null);
        setRegion(null);
    };

    const handleFilter = () => {
        applyFilters({ region, year, tool });
        close();
    };

    return (
        <Fragment>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card job-filter">
                        <div className="card-body p-3">
                            <div className="row">
                                {/* <div className="col-12 mb-3">
                                    <div className="position-relative">
                                        <Select
                                            value={region}
                                            onChange={setRegion}
                                            options={regions}
                                            placeholder="Select Region"
                                            isClearable
                                        />
                                    </div>
                                </div> */}
                                <div className="col-12 mb-3">
                                    <div className="position-relative">
                                        <Select
                                            value={year}
                                            onChange={setYear}
                                            options={years}
                                            placeholder="Select Year"
                                            isClearable
                                        />
                                    </div>
                                </div>
                                <div className="col-12 mb-4">
                                    <div className="position-relative">
                                        <Select
                                            value={tool}
                                            onChange={setTool}
                                            options={tools}
                                            placeholder="Select Tool"
                                            isClearable
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="position-relative h-100 hstack">
                                        <div className="flex-shrink-0">
                                            <a href="#!" onClick={handleFilter} className="btn btn-warning mx-2">
                                                <i className="mdi mdi-refresh"></i> Apply Filter
                                            </a>
                                            <a href="#!" onClick={clearFilters} className="btn btn-primary">
                                                <i className="mdi mdi-filter-outline align-middle"></i> Clear Filter
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default AverageFilter;