import React, { useState, useEffect, Fragment } from 'react';
import API from '../../../helpers/api';
import { Spinner } from 'react-bootstrap';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Licensed = () => {
    const [loadingGender, setLoadingGender] = useState(false);
    const [loadingQualification, setLoadingQualification] = useState(false);
    const [data, setData] = useState([]);
    const [qualification, setQualification] = useState([]);
    const [colors, setColors] = useState([]);

    const COLORS = ['#0088FE', '#FF8042'];

    const generateRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    const loadGender = async () => {
        setLoadingGender(true);
        try {
            const res = await API.get(`/umdpc/gender`);
            const chartData = res.data.map((item) => ({
                name: item.gender,
                value: parseInt(item.total, 10),
            }));
            setData(chartData);
        } catch (error) {
            console.error('Error fetching gender data:', error);
        } finally {
            setLoadingGender(false);
        }
    };

    const loadQualifications = async () => {
        setLoadingQualification(true);
        try {
            const res = await API.get('/umdpc/qualification');
            console.log('Qualifications data:', res.data);

            const chartData = res.data.map((item) => ({
                name: item.qualification,
                value: parseInt(item.count, 10),
            }));

            const colorPalette = chartData.map(() => generateRandomColor());

            setColors(colorPalette);
            setQualification(chartData);
        } catch (error) {
            console.error('Error fetching qualification data:', error);
        } finally {
            setLoadingQualification(false);
        }
    };

    useEffect(() => {
        loadGender();
        loadQualifications();
    }, []);

    return (
        <Fragment>
            <div className="row">
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">% of Licensed Health Workers By Gender</h4>
                            {loadingGender ? (
                                <Spinner />
                            ) : (
                                <ResponsiveContainer width="100%" height={300}>
                                    <PieChart>
                                        <Pie
                                            data={data}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                                            outerRadius={120} // Reduced outer radius
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {data.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                        <Legend />
                                    </PieChart>
                                </ResponsiveContainer>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">% of Licensed Health Workers By Qualification</h4>
                            {loadingQualification ? (
                                <Spinner />
                            ) : (
                                <ResponsiveContainer width="100%" height={300}>
                                    <PieChart>
                                        <Pie
                                            data={qualification}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                                            outerRadius={120} // Reduced outer radius
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {qualification.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                        <Legend />
                                    </PieChart>
                                </ResponsiveContainer>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Licensed;