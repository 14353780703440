import React, { useState, useEffect, Fragment } from 'react';
import API from '../../../helpers/api';
import { Spinner } from 'react-bootstrap';
// import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';


const Licensed = () => {
    const [loadingGender, setLoadingGender] = useState(false);
    const [loadingQualification, setLoadingQualification] = useState(false);
    const [data, setData] = useState([]);
    const [qualification, setQualification] = useState([]);
    const [colors, setColors] = useState([]);
    const [totalLicenses, setTotalLicenses] = useState(0);

    const COLORS = ['#0088FE', '#FF8042'];

    const generateRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    const loadGender = async () => {
        setLoadingGender(true);
        try {
            const res = await API.get(`/ahpc/gender`);
            const chartData = res.data.map((item) => ({
                name: item.gender,
                value: parseInt(item.total, 10),
            }));
            setData(chartData);
        } catch (error) {
            console.error('Error fetching gender data:', error);
        } finally {
            setLoadingGender(false);
        }
    };

    const loadQualifications = async () => {
        setLoadingQualification(true); // Start loading for qualifications
        try {
            const res = await API.get('/ahpc/qualification');
            console.log('Qualifications data:', res.data); // Add a console log to inspect the data

            // Calculate total licenses for percentage calculation
            const totalLicensesCount = res.data.reduce((total, item) => total + parseInt(item.count, 10), 0);
            setTotalLicenses(totalLicensesCount);

            // Map the response data into percentages
            const chartData = res.data.map((item) => ({
                name: item.qualification,
                value: (parseInt(item.count, 10) / totalLicensesCount) * 100, // Convert to percentage
            }));

            // Generate random colors for each qualification
            const colorPalette = chartData.map(() => generateRandomColor());

            // Set the data and colors for the chart
            setColors(colorPalette);
            setQualification(chartData);
        } catch (error) {
            console.error('Error fetching qualification data:', error);
        } finally {
            setLoadingQualification(false); // Stop loading
        }
    };

    const percentFormatter = (tick) => `${tick.toFixed(0)}%`;

    useEffect(() => {
        loadGender();
        loadQualifications();
    }, []);

    return (
        <Fragment>
            <div className="row">
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">% of Licensed Health Workers By Gender</h4>
                            {loadingGender ? (
                                <Spinner />
                            ) : (
                                <ResponsiveContainer width="100%" height={300}>
                                    <PieChart>
                                        <Pie
                                            data={data}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                                            outerRadius={120} // Reduced outer radius
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {data.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                        <Legend />
                                    </PieChart>
                                </ResponsiveContainer>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-lg-8">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">% of Licensed Health Workers By Qualification</h4>
                            {loadingQualification ? (
                                <Spinner />
                            ) : (
                                <ResponsiveContainer width="100%" height={300}>
                                    <BarChart
                                        layout="vertical"
                                        data={qualification}
                                        margin={{ top: 20, right: 30, left: 50, bottom: 20 }} // Adjust margins for Y-axis label space
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis type="number" tickFormatter={percentFormatter} domain={[0, 100]} /> {/* Values on the X axis as percentage */}
                                        <YAxis
                                            type="category"
                                            dataKey="name"
                                            tick={{ fontSize: 12 }} // Adjust font size if needed
                                            width={150} // Increase width to fit long labels
                                            tickFormatter={(name) => (name.length > 40 ? `${name.slice(0, 40)}...` : name)} // Truncate long labels
                                        />
                                        <Tooltip formatter={(value) => `${value.toFixed(2)}%`} />
                                        <Legend />
                                        <Bar dataKey="value" fill="#8884d8" barSize={20}> {/* Reduced bar size */}
                                            {qualification.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                            ))}
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Licensed;