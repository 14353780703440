import React, { Fragment, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, LabelList } from 'recharts';
import API from '../../helpers/api';
import Filters from './Filters';
import FNModal from '../../components/FNModal';

const District = () => {
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(false);
    const [showOpen, setShowOpen] = useState(false);

    const show = () => setShowOpen(true);
    const close = () => setShowOpen(false);

    const loadData = async (selectedFilters = {}) => {
        setFilters(selectedFilters);
        setLoading(true);
        try {
            const res = await API.get('/assessment/districts', {
                params: {
                    region: selectedFilters.region?.value || '',
                    year: selectedFilters.year?.value || '',
                    tool: selectedFilters.tool?.value || ''
                }
            });

            const chartData = res.data.map((item) => ({
                district: item.district,
                performance: item.performance,
            }));

            setData(chartData);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Fragment>
            <FNModal
                showModal={showOpen}
                handleClose={close}
                lg="lg"
                title="Filter By Region and Year"
            >
                <Filters close={close} applyFilters={loadData} />
            </FNModal>

            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="mb-4 d-sm-flex align-items-center justify-content-between">
                                        <h4 className="card-title mb-4">
                                            {filters.region?.value || 'All Regions'} Region
                                            {filters.year?.value ? ` ${filters.year.value}` : ''}
                                            Districts Overall Performance
                                        </h4>
                                        <div className="page-title-right">
                                            <div className="text-sm-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-warning waves-effect waves-light"
                                                    onClick={show}
                                                >
                                                    Filter By Region & Year
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {loading ? (
                                <Spinner />
                            ) : data.length > 0 ? (
                                <BarChart
                                    width={900}
                                    height={500}
                                    data={data}
                                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="district" angle={-45} textAnchor="end" height={100} interval={0} />
                                    <YAxis domain={[0, 100]} allowDecimals={false} />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="performance" fill="#8884d8" name="Overall Performance">
                                        <LabelList dataKey="performance" position="top" formatter={(value) => `${value}%`} />
                                    </Bar>
                                </BarChart>
                            ) : (
                                <p>No data available. Apply filters to display results.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default District;