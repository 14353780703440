import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import API from "../../../helpers/api";
import Filters from './Filter';

const AssessedFacilities = () => {
    const [loading, setLoading] = useState(false);
    const [scores, setScores] = useState([]);
    const [filters, setFilters] = useState({});

    const loadData = async (filterParams = {}) => {
        setLoading(true);
        try {
            // Convert the filterParams object into a query string
            const query = new URLSearchParams(filterParams).toString();
            const res = await API.get(`/assessed?${query}`);
            console.log("response ==>", res);
            setScores(res?.data || []);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const handleFilterChange = (selectedFilters) => {
        setFilters(selectedFilters); // Store the selected filters
        loadData(selectedFilters);   // Fetch the filtered data
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        console.log("Updated Scores:", scores);
    }, [scores]);

    return (
        <Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="mb-4 d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Facilities Assessed</h4>
                    </div>
                </div>
            </div>
            <Filters onFilterChange={handleFilterChange} />
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        {/* {scores.map((score) => (
                            <div className="col-2" key={score.module}>
                                <div className={`card mini-stats-wid ${getCardClass(score.module)}`}>
                                    <div className="card-body text-center">
                                        <h5 className="text-muted fw-medium">{`${score.module}`}</h5>
                                        <h6 className="m-0 font10">
                                            <strong data-plugin="counterup">{`${score.percentage_true_score} (%) `}</strong>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        ))} */}
                        <div class="table-responsive">
                            <table class="table table-sm">
                                <thead class="table-light">
                                    <tr>
                                        {/* <th>Facility</th> */}
                                        <th>Region</th>
                                        <th>District</th>
                                        <th>Facility</th>
                                        <th>Level</th>
                                        <th>Ownership</th>
                                        <th>Assessment Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? <Spinner /> : scores.map((row, index) => (
                                        <tr key={index}>
                                            <td>{row.region}</td>
                                            <td>{row.district}</td>
                                            <td>{row.dhis2_name}</td>
                                            <td>{row.levelofcare}</td>
                                            <td>{row.ownership}</td>
                                            <td>{moment(row.enrollment_date).format('YYYY-MM-DD')}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default AssessedFacilities;
