import React, { Fragment, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, LabelList } from 'recharts';
import API from '../../helpers/api';
import AverageFilter from './AverageFilter';
import FNModal from '../../components/FNModal';

const AveragePerformance = () => {
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(false);
    const [showOpen, setShowOpen] = useState(false);

    const show = () => setShowOpen(true);
    const close = () => setShowOpen(false);

    const loadData = async (selectedFilters = {}) => {
        setFilters(selectedFilters);
        setLoading(true);
        try {
            const res = await API.get('/assessment/scores', {
                params: {
                    region: selectedFilters.region?.value || '',
                    year: selectedFilters.year?.value || '',
                    tool: selectedFilters.tool?.value || ''
                }
            });

            // Transform response into an array of objects with name and value, then sort
            const chartData = [
                { name: 'Governance Leadership', value: res.data[0].governance_leadership },
                { name: 'HR Management', value: res.data[0].hr_management },
                { name: 'Occupational Health & Safety', value: res.data[0].cccupational_health_safety },
                { name: 'Infrastructure', value: res.data[0].infrastructure },
                { name: 'Health Promotion', value: res.data[0].health_promotion },
                { name: 'Essential Care', value: res.data[0].essential_care },
                { name: 'Maternity Services', value: res.data[0].maternity_services },
                { name: 'Mortuary', value: res.data[0].mortuary },
                { name: 'Laboratory Services', value: res.data[0].laboratory_services },
                { name: 'Theatre Services', value: res.data[0].theatre_services },
                { name: 'Infection Prevention & Control', value: res.data[0].infection_prevention_control },
                { name: 'Client-Provider Interactions', value: res.data[0].client_provider_interactions },
                { name: 'Equipment', value: res.data[0].equipment },
                { name: 'Medicines & Health Supplies', value: res.data[0].medicines_health_supplies },
                { name: 'Records', value: res.data[0].records },
                { name: 'Clinical Services', value: res.data[0].clinical_services }
            ].sort((a, b) => b.value - a.value); // Sort by value in descending order

            setData(chartData);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Fragment>
            <FNModal
                showModal={showOpen}
                handleClose={close}
                lg="lg"
                title="Average Performance Scores Filters"
            >
                <AverageFilter close={close} applyFilters={loadData} />
            </FNModal>

            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="mb-4 d-sm-flex align-items-center justify-content-between">
                                        <h4 className="card-title mb-4">
                                            Average Performance of Facilities Fully Assessed in {filters.tool?.value || ''}
                                        </h4>
                                        <div className="page-title-right">
                                            <div className="text-sm-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-warning waves-effect waves-light"
                                                    onClick={show}
                                                >
                                                    Apply Filters
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {loading ? (
                                <Spinner />
                            ) : data.length > 0 ? (
                                <BarChart
                                    width={1000}
                                    height={500}
                                    data={data}
                                    margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" interval={0} angle={-20} textAnchor="end" />
                                    <YAxis domain={[0, 100]} allowDecimals={false} tickFormatter={(tick) => `${tick}%`} />
                                    <Tooltip formatter={(value) => `${value}%`} />
                                    <Bar dataKey="value" fill="#4682B4" name="Average Performance">
                                        <LabelList dataKey="value" position="top" formatter={(value) => `${value}%`} />
                                    </Bar>
                                </BarChart>
                            ) : (
                                <p>No data available. Apply filters to display results.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default AveragePerformance;