import React, { Fragment, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, LabelList } from 'recharts';
import API from '../../helpers/api';

const FacilityRange = () => {
    const [data, setData] = useState([]);
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [maxValue, setMaxValue] = useState(0);
    const [maxRange, setMaxRange] = useState(0);

    const loadScoreRanges = async () => {
        setLoading(true);
        try {
            const res = await API.get('/assessment/range');
            const chartData = res.data
                .map((item) => ({
                    range: item.score_range,
                    count: item.number_of_facilities,
                }))
                .sort((a, b) => {
                    const ranges = ['0-49', '50-59', '60-69', '70-79', '80-89', '90-100'];
                    return ranges.indexOf(a.range) - ranges.indexOf(b.range);
                });

            const maxCount = Math.max(...chartData.map((item) => item.count));
            setMaxValue(maxCount + 12);

            setData(chartData);
            setLoading(false);
        } catch (error) {
            console.log('Error fetching data:', error);
            setLoading(false);
        }
    };

    const loadData = async () => {
        setLoading(true);
        try {
            const res = await API.get('/assessment/services');

            // Transform the response data to match the Recharts format and filter out zero counts
            const transformedData = res.data.reduce((acc, item) => {
                if (item.number_of_facilities === 0) return acc;

                const range = item.score_range;
                const service = item.services_provided;

                // Initialize the score range in the accumulator if it doesn't exist
                if (!acc[range]) {
                    acc[range] = { range, outPatient: 0, outpatientAndInpatient: 0 };
                }

                // Add the facility count based on the service type
                if (service.toLowerCase() === "out patient") {
                    acc[range].outPatient += item.number_of_facilities;
                } else if (service.toLowerCase() === "outpatient and inpatient") {
                    acc[range].outpatientAndInpatient += item.number_of_facilities;
                }

                return acc;
            }, {});

            // Convert the accumulator object back to an array and calculate the max value for the Y-axis
            const finalData = Object.values(transformedData);
            const maxCount = Math.max(
                ...finalData.flatMap((item) => [item.outPatient, item.outpatientAndInpatient])
            );
            setMaxRange(maxCount + 12); // Add a buffer to the max value

            setServices(finalData);
            setLoading(false);
        } catch (error) {
            console.log('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData();
        loadScoreRanges();
    }, []);

    const colors = ['#FF6347', '#FFA500', '#FFD700', '#ADFF2F', '#32CD32', '#4682B4'];

    return (
        <Fragment>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Number of Facilities Per Score Ranges</h4>
                            {loading ? (
                                <Spinner />
                            ) : (

                                <BarChart width={900} height={400} data={data}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="range" />
                                    <YAxis domain={[0, maxValue]} allowDecimals={false} />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="count" fill="#4682B4" name="Number of Facilities">
                                        <LabelList dataKey="count" position="top" />
                                    </Bar>
                                </BarChart>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Number of Facilities Per Score Ranges Per Services Offered</h4>
                            {loading ? (
                                <Spinner />
                            ) : (

                                <BarChart width={900} height={400} data={services} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="range" />
                                    <YAxis domain={[0, maxRange]} allowDecimals={false} />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="outPatient" fill="#6a0dad" name="Out Patient Services">
                                        <LabelList dataKey="outPatient" position="top" formatter={(value) => Number(value)} />
                                    </Bar>
                                    <Bar dataKey="outpatientAndInpatient" fill="#FF6347" name="Outpatient and Inpatient Sevices">
                                        <LabelList dataKey="outpatientAndInpatient" position="top" formatter={(value) => Number(value)} />
                                    </Bar>
                                </BarChart>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FacilityRange;
