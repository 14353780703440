import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from "react-bootstrap";
import 'react-datepicker/dist/react-datepicker.css';
import API from "../../../helpers/api";

const DetailsFilter = ({ onFilterChange }) => {

    const [loading, setLoading] = useState(false);
    const [module, setModule] = useState("");
    const [modules, setModules] = useState([]);

    const loadModules = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/filters/stands`);
            setModules(res?.data || []);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadModules();
    }, []);

    const handleFilterChange = async () => {
        const params = {};

        if (module) params.module = module;
        onFilterChange(params);

        console.log("Filtered Params ===>", params)
    };

    const clear = () => setModule("");

    return (
        <div class="row">
            <div class="col-lg-12">
                <div class="card job-filter">
                    <div class="card-body p-3">
                        <div class="row">
                            <div class="col-3">
                                <div class="position-relative">
                                    <label>Filter By Module</label>
                                    <select
                                        className="form-select"
                                        value={module}
                                        onChange={(e) => setModule(e.target.value)}
                                    >
                                        <option value="">Select Module</option>
                                        {modules.map((r, index) => (
                                            <option key={index} value={r.module}>{r.module}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="position-relative h-100 hstack gap-3">
                                    <div class="flex-shrink-0">
                                        <Link to="/hfqap/facility/scores" class="btn btn-primary">
                                            <i class="mdi mdi-filter-outline align-middle"></i>Back</Link>
                                        {/* <a href="#!" onClick={clear} class="btn btn-light mx-2"><i class="mdi mdi-refresh"></i> Clear</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailsFilter