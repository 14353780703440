/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, Fragment } from 'react'
import API from "../../helpers/api";
import Authority from './Authority';
import Ownership from './Ownership';
import Facilities from './Facilities';
import Trend from "./Trend";
import AverageScores from './AverageScores';
import FacilityRange from './FacilityRange';
import District from './District';
import AveragePerformance from './AveragePerformance';

const Sqis = () => {

    const [loading, setLoading] = useState(false);
    const [pfp, setPFP] = useState(0);
    const [pnfp, setPNFP] = useState(0);
    const [ahpc, setAHPC] = useState(0);
    const [unmc, setUNMC] = useState(0);
    const [unmdpc, setUNMDPC] = useState(0);
    const [activeTab, setActiveTab] = useState('average');

    const loadData = async () => {
        setLoading(true);
        try {
            const response = await API.get('/uhf');
            console.log(response)
            response.data.forEach(item => {
                switch (item.registration_authority) {
                    case 'UNMC':
                        setUNMC(item.count);
                        break;
                    case 'AHPC':
                        setAHPC(item.count);
                        break;
                    case 'UMDPC':
                        setUNMDPC(item.count);
                        break;
                    case 'PFP':
                        setPFP(item.count);
                        break;
                    case 'PFNFP':
                        setPNFP(item.count);
                        break;

                    default:
                        break;
                }
            });
        } catch (error) {
            console.error('Error fetching facility counts:', error);
        } finally {
            setLoading(false);
        }
    };

    const loadOwner = async () => {
        setLoading(true);
        try {
            const response = await API.get('/uhf/ownership');
            console.log(response)
            response.data.forEach(item => {
                switch (item.facility_ownership) {
                    case 'PFP':
                        setPFP(item.count);
                        break;
                    case 'PNFP':
                        setPNFP(item.count);
                        break;
                    default:
                        break;
                }
            });
        } catch (error) {
            console.error('Error fetching facility counts:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadOwner();
        loadData();
    }, []);

    const renderContent = () => {
        switch (activeTab) {
            case 'authority':
                return (
                    <Authority />
                );
            case 'ownership':
                return (
                    <Ownership />
                );
            case 'trend':
                return (
                    <Trend />
                );
            case 'facilities':
                return (
                    <Facilities />
                );
            case 'range':
                return (
                    <FacilityRange />
                );
            case 'district':
                return (
                    <District />
                );
            case 'performance':
                return (
                    <AveragePerformance />
                );
            case 'average':
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">SQIS Service Elements Average Scores</h4>
                                    <AverageScores />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'deployed':
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">% of Health Facilities Assessed By Type</h4>
                                    <Facilities />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'cadre':
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">% Performance of Facilities Assessed General </h4>
                                    <Facilities />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'training':
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">% Performance of Facilities Assessed General </h4>
                                    <Facilities />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <Fragment>
            <div class="row">
                <div class="col-12">
                    <div class="mb-4 d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0 font-size-18">Private Sector Facility Quality Assessment Dashboard - Support Supervision - SQIS+</h4>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md">
                    <div class="card mini-stats-wid widget-two-primary">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Private For Profit Facilities</p>
                                    <h4 class="mb-0">{pfp !== null ? pfp : '0'} </h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-primary">
                                            <i class="bx bx-purchase-tag-alt font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid widget-two-success">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Private Not For Profit Facilities</p>
                                    <h4 class="mb-0">{pnfp && pnfp}</h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                        <span class="avatar-title">
                                            <i class="bx bx-copy-alt font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid widget-two-info">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Total Number UNMC Assessed Facilities</p>
                                    <h4 class="mb-0">{unmc && unmc}</h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-primary">
                                            <i class="bx bx-archive-in font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid widget-two-warning">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Total Number AHPC Assessed Facilities</p>
                                    <h4 class="mb-0">{ahpc && ahpc}</h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-primary">
                                            <i class="bx bx-purchase-tag-alt font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid widget-three">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Total Number UMDPC Facilities</p>
                                    <h4 class="mb-0">{unmdpc && unmdpc}</h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-primary">
                                            <i class="bx bx-purchase-tag-alt font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                {/* <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'authority' ? 'active' : ''}`}
                        onClick={() => setActiveTab('authority')}
                        role="tab"
                        aria-selected={activeTab === 'authority'}
                    >
                        Facilities By Authority
                    </a>
                </li> */}
                {/* <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'ownership' ? 'active' : ''}`}
                        onClick={() => setActiveTab('ownership')}
                        role="tab"
                        aria-selected={activeTab === 'ownership'}
                    >
                        Facilities By Ownership
                    </a>
                </li> */}
                {/* <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'trend' ? 'active' : ''}`}
                        onClick={() => setActiveTab('trend')}
                        role="tab"
                        aria-selected={activeTab === 'trend'}
                    >
                        Trend Over Assessments
                    </a>
                </li> */}
                <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'average' ? 'active' : ''}`}
                        onClick={() => setActiveTab('average')}
                        role="tab"
                        aria-selected={activeTab === 'average'}
                    >
                        Service Elements Average Scores
                    </a>
                </li>
                <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'range' ? 'active' : ''}`}
                        onClick={() => setActiveTab('range')}
                        role="tab"
                        aria-selected={activeTab === 'range'}
                    >
                        Facilities Per Score Range
                    </a>
                </li>
                <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'district' ? 'active' : ''}`}
                        onClick={() => setActiveTab('district')}
                        role="tab"
                        aria-selected={activeTab === 'district'}
                    >
                        District Assessment Performance
                    </a>
                </li>
                <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'performance' ? 'active' : ''}`}
                        onClick={() => setActiveTab('performance')}
                        role="tab"
                        aria-selected={activeTab === 'performance'}
                    >
                        Average Performance Scores
                    </a>
                </li>
                <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'facilities' ? 'active' : ''}`}
                        onClick={() => setActiveTab('facilities')}
                        role="tab"
                        aria-selected={activeTab === 'facilities'}
                    >
                        Facilities Assessed
                    </a>
                </li>
            </ul>
            <div className="tab-content p-3">
                {/* <div className={`tab-pane ${activeTab === 'authority' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'authority' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'ownership' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'ownership' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'deployed' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'deployed' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'cadre' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'cadre' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'training' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'training' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'trend' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'trend' && renderContent()}
                </div> */}
                <div className={`tab-pane ${activeTab === 'average' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'average' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'range' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'range' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'district' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'district' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'performance' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'performance' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'facilities' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'facilities' && renderContent()}
                </div>
            </div>
        </Fragment>
    )
}

export default Sqis