import React, { useState, Fragment } from 'react';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import API from "../../../helpers/api";
import Filters from './Filter';
import FNModal from "../../../components/FNModal";

const Performance = () => {
    const [loading, setLoading] = useState(false);
    const [showOpen, setShowOpen] = useState(false);
    const [filters, setFilters] = useState({});
    const [columns, setColumns] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const show = () => setShowOpen(true);
    const close = () => setShowOpen(false);

    const columnDisplayNames = {
        created_at: "Date",
        tool: "Tool",
        district: "District",
        facility_name: "Facility",
        "GOVERNANCE, LEADERSHIP & MANAGEMENT": "Governance Leadership",
        "HUMAN RESOURCE MANAGEMENT": "HR Management",
        INFRASTRUCTURE: "Infrastructure",
        "HEALTH PROMOTION AND EDUCATION": "Health Promotion",
        "ESSENTIAL CARE": "Essential Care",
        "CLINICAL SERVICES": "Clinical Services",
        EQUIPMENT: "Equipment",
        facility_score: "Facility Score",
        "OCCUPATIONAL HEALTH AND SAFETY": "Occupational Health",
        "MATERNITY SERVICES": "Maternity Services",
        "MORTUARY": "Mortuary",
        "LABORATORY SERVICES": "Laboratory Services",
        "THEATRE SERVICES": "Theatre Services",
        "INFECTION PREVENTION AND CONTROL": "Infection Prevention",
        "CLIENT-PROVIDER INTERACTIONS": "Client Provider",
        "MEDICINES AND HEALTH SUPPLIES": "Medicines Supplies",
        "RECORDS": "Records",
        "RADIATION SAFETY": "Radiation Safety",
        "MEDICAL LABORATORY SERVICE": "Medical Laboratory",
        "PHARMACEUTICAL SERVICES": "Pharmaceutical Services",
    };

    const toolColumns = {
        "Dentistry Tool": ["facility_name", "GOVERNANCE, LEADERSHIP & MANAGEMENT", "HUMAN RESOURCE MANAGEMENT",
            "INFRASTRUCTURE", "HEALTH PROMOTION AND EDUCATION", "ESSENTIAL CARE", "CLINICAL SERVICES", "EQUIPMENT", "facility_score"
        ],
        "Health Facility Tool": ["facility_name", "GOVERNANCE, LEADERSHIP & MANAGEMENT", "HUMAN RESOURCE MANAGEMENT",
            "INFRASTRUCTURE", "HEALTH PROMOTION AND EDUCATION", "ESSENTIAL CARE", "CLINICAL SERVICES", "EQUIPMENT",
            "LABORATORY SERVICES", "MEDICINES AND HEALTH SUPPLIES", "CLIENT-PROVIDER INTERACTIONS", "RECORDS",
            "INFECTION PREVENTION AND CONTROL", "OCCUPATIONAL HEALTH AND SAFETY", "THEATRE SERVICES", "MATERNITY SERVICES",
            "MORTUARY", "facility_score"
        ],
        "Laboratory Tool": ["facility_name", "GOVERNANCE, LEADERSHIP & MANAGEMENT",
            "HUMAN RESOURCE MANAGEMENT", "INFRASTRUCTURE", "HEALTH PROMOTION AND EDUCATION", "ESSENTIAL CARE",
            "MEDICAL LABORATORY SERVICE", "facility_score"
        ],
        "Radiology Tool": ["facility_name", "GOVERNANCE, LEADERSHIP & MANAGEMENT", "HUMAN RESOURCE MANAGEMENT",
            "INFRASTRUCTURE", "HEALTH PROMOTION AND EDUCATION", "RADIATION SAFETY", "ESSENTIAL CARE", "CLINICAL SERVICES", "facility_score"
        ],
        "Pharmacy Tool": ["facility_name", "GOVERNANCE, LEADERSHIP & MANAGEMENT", "HUMAN RESOURCE MANAGEMENT",
            "INFRASTRUCTURE", "HEALTH PROMOTION AND EDUCATION", "RADIATION SAFETY", "ESSENTIAL CARE", "CLINICAL SERVICES", "facility_score"
        ],
    };

    const applyFilters = async (selectedFilters) => {
        setFilters(selectedFilters);
        setLoading(true);

        if (selectedFilters.tool) {
            const selectedTool = selectedFilters.tool.value;
            setColumns(toolColumns[selectedTool] || []);
        }

        try {
            const response = await API.get('/assessment/filters', {
                params: {
                    region: selectedFilters.region?.value || "",
                    year: selectedFilters.year?.value || "",
                    tool: selectedFilters.tool?.value || "",
                    district: selectedFilters.district?.value || "",
                    division: selectedFilters.division?.value || "",
                    facility: selectedFilters.facility?.value || ""
                }
            });
            setFilteredData(response.data);
        } catch (error) {
            console.error('Error fetching filtered data:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <FNModal
                showModal={showOpen}
                handleClose={close}
                lg="lg"
                title="Select SQIS+ Assessment Filters"
            >
                <Filters close={close} applyFilters={applyFilters} />
            </FNModal>
            <div className="row">
                <div className="col-12">
                    <div className="mb-4 d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">SQIS+ - Facilities Assessment Performance { } </h4>
                        <div className="page-title-right">
                            <div className="text-sm-end">
                                <button
                                    type="button"
                                    className="btn btn-warning waves-effect waves-light"
                                    onClick={show}
                                >
                                    Filter Data
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    {loading ? (
                        <Spinner />
                    ) : filteredData.length > 0 ? (
                        <div className="table-responsive">
                            <table className="table align-middle table-sm">
                                <thead className="table-light">
                                    <tr>
                                        {columns.map((col, index) => (
                                            <th
                                                key={index}
                                                style={col === "facility_name" ? { width: '20%' } : {}}
                                            >
                                                {columnDisplayNames[col] || col}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.map((item, index) => (
                                        <tr key={index}>
                                            {columns.map((col, colIndex) => {
                                                const value = item[col] || 'N/A';
                                                const backgroundColor =
                                                    col === "facility_name" ? 'transparent' :
                                                        value >= 0 && value < 50 ? 'red' :
                                                            value >= 50 && value < 80 ? 'yellow' :
                                                                value >= 80 ? 'green' : 'grey';

                                                return (
                                                    <td
                                                        key={colIndex}
                                                        style={{
                                                            backgroundColor,
                                                            width: col === "facility_name" ? '20%' : 'auto'
                                                        }}
                                                    >
                                                        {col === "created_at"
                                                            ? moment(value).format("YYYY-MM-DD")
                                                            : value}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <p>No data available. Apply filters to display results.</p>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default Performance;